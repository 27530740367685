import {
  SeatMapCabinRowSectionElement,
  SeatMapCabinRowSectionElementSeat,
} from "../types/SeatMap";

export function isSeatElement(
  element: SeatMapCabinRowSectionElement
): element is SeatMapCabinRowSectionElementSeat {
  return element.type === "seat";
}
;
import "/Users/igor/Duffel/duffel-components/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";
{
  "name": "@duffel/components",
  "version": "1.1.0",
  "private": true,
  "license": "MIT",
  "main": "index.js",
  "scripts": {
    "prebuild": "rm -rf ./dist",
    "build": "node config/esbuild.build.config.js",
    "build-storybook": "storybook build",
    "check-types": "tsc --project tsconfig.json --noEmit --emitDeclarationOnly false",
    "dev": "node config/esbuild.dev.config.js",
    "gcloud-storage-cp": "bash ./scripts/gcloud-storage-cp.sh",
    "generate-fixture": "esbuild ./scripts/generate-fixture.ts --outfile=dist/scripts/generate-fixture.cjs --format=cjs --platform=node --bundle --tsconfig=scripts.tsconfig.json",
    "postgenerate-fixture": "node dist/scripts/generate-fixture.cjs",
    "generate-type-declarations": "tsc --project tsconfig.json",
    "commit": "cz",
    "lint": "eslint . --ext .ts,.tsx,.mjs,.js --max-warnings=0",
    "pre-commit-check": "concurrently --raw \"yarn check-types\" \"yarn lint-staged\"",
    "prepare": "husky install",
    "prettier": "prettier --write --loglevel=warn .",
    "prettier-check": "prettier --check .",
    "prereact-build": "rm -rf ./react-dist",
    "react-build": "node config/react-esbuild.config.js",
    "postreact-build": "yarn generate-type-declarations",
    "run-client-side-server": "http-server src/examples/client-side --port=6262",
    "run-example:client-side": "concurrently \"yarn dev\" \"yarn run-client-side-server\"",
    "run-example:full-stack": "concurrently \"yarn dev\" \"yarn run-full-stack-server\"",
    "run-full-stack-server": "node src/examples/full-stack/server.mjs",
    "storybook": "storybook dev -p 6006",
    "test": "LANG='en_US' TZ=UTC yarn jest",
    "preupload-to-cdn": "yarn build",
    "upload-to-cdn": "bash ./scripts/upload-to-cdn.sh",
    "release": "yarn semantic-release",
    "get-version": "cat package.json | jq '.version'",
    "post-commit-check": "yarn commitlint --from main"
  },
  "husky": {
    "hooks": {
      "pre-commit": "yarn pre-commit-check",
      "post-commit": "yarn post-commit"
    }
  },
  "lint-staged": {
    "*.{ts,tsx,mjs,js}": [
      "eslint --fix",
      "prettier --write"
    ]
  },
  "dependencies": {
    "@sentry/browser": "^7.43.0",
    "classnames": "^2.3.2",
    "react": "18.2.0",
    "react-dom": "18.2.0"
  },
  "devDependencies": {
    "@babel/core": "^7.21.4",
    "@babel/preset-env": "^7.21.4",
    "@babel/preset-react": "^7.18.6",
    "@babel/preset-typescript": "^7.21.4",
    "@commitlint/cli": "^17.6.5",
    "@commitlint/config-conventional": "^17.6.5",
    "@duffel/api": "^1.26.0",
    "@sentry/esbuild-plugin": "^0.7.0",
    "@storybook/addon-essentials": "^7.0.2",
    "@storybook/addon-interactions": "^7.0.2",
    "@storybook/addon-links": "^7.0.2",
    "@storybook/addon-storyshots": "^7.0.4",
    "@storybook/blocks": "^7.0.2",
    "@storybook/react": "^7.0.2",
    "@storybook/react-webpack5": "^7.0.2",
    "@storybook/testing-library": "^0.1.0",
    "@testing-library/react": "^14.0.0",
    "@types/jest": "^29.5.0",
    "@types/node": "^18.15.11",
    "@types/prompts": "^2.4.4",
    "@types/react": "^18.0.32",
    "@types/react-dom": "^18.0.11",
    "@typescript-eslint/eslint-plugin": "^5.43.0",
    "@typescript-eslint/parser": "^5.57.0",
    "babel-jest": "^29.5.0",
    "commitizen": "^4.3.0",
    "concurrently": "^8.0.0",
    "cz-conventional-changelog": "^3.3.0",
    "dotenv": "^16.0.3",
    "esbuild": "0.17.19",
    "esbuild-copy-static-files": "^0.1.0",
    "eslint": "^8.0.1",
    "eslint-config-prettier": "^8.8.0",
    "eslint-plugin-import": "^2.25.2",
    "eslint-plugin-jsx-a11y": "^6.7.1",
    "eslint-plugin-react": "^7.32.2",
    "eslint-plugin-react-hooks": "^4.6.0",
    "eslint-plugin-storybook": "^0.6.11",
    "http-server": "^14.1.1",
    "husky": "^8.0.3",
    "jest": "^29.5.0",
    "jest-environment-jsdom": "^29.5.0",
    "lint-staged": "^13.2.0",
    "prettier": "^2.8.7",
    "prettier-plugin-organize-imports": "^3.2.2",
    "prompts": "^2.4.2",
    "prop-types": "^15.8.1",
    "semantic-release": "^21.0.3",
    "storybook": "^7.0.2",
    "ts-node": "^10.9.1",
    "tsconfig-paths-webpack-plugin": "^4.0.1",
    "typescript": "5.0.4"
  },
  "engines": {
    "node": "~18.*"
  },
  "config": {
    "commitizen": {
      "path": "./node_modules/cz-conventional-changelog"
    }
  },
  "plugins": [
    [
      "@semantic-release/commit-analyzer"
    ],
    [
      "@semantic-release/release-notes-generator"
    ],
    "@semantic-release/npm",
    "@semantic-release/github"
  ],
  "release": {
    "branches": [
      "main"
    ]
  }
}

import { CreateOrderPayload } from "../types/CreateOrderPayload";

export const isPayloadComplete = (
  payload: Partial<CreateOrderPayload>
): payload is CreateOrderPayload =>
  "selected_offers" in payload &&
  "passengers" in payload &&
  "services" in payload &&
  "payments" in payload &&
  "type" in payload &&
  "metadata" in payload;
;
import "/Users/igor/Duffel/duffel-components/node_modules/@sentry/bundler-plugin-core/sentry-release-injection-file.js";